export default class BlockCustomerStrateMurSocial {
    constructor() {
        if ($('[data-element="block-strate-mur-social"]').length > 0) {
            BlockCustomerStrateMurSocial.socialWall()
        }
    }

    static socialWall() {
        const container = '[data-id="social-wall"]'
        if (
            $(container).length > 0 &&
            !$(container).is(':hidden') &&
            window.matchMedia('(min-width: 650px)').matches &&
            !$('body').hasClass('eco-mode-active')
        ) {
            import('@wordpress/api-fetch').then(({ default: apiFetch }) => {
                import('@wordpress/i18n').then(
                    ({ __ }) => {
                        apiFetch({
                            path: 'iris-sso-instagram/v1/get-feed',
                            method: 'post',
                            data: { limit: 7 },
                        }).then((result) => {
                            const htmlClean = $(result.render).find('.instagramList').html();
                            $(container) .find('.block__customer__strate-mur-social__text').after(htmlClean);
                            import('@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component').then(({ HcLazyload }) => {
                                HcLazyload.update()
                            })
                        }).catch((e) => {
                            console.log(e);
                            console.log('Désolé, une erreur est survenue pendant le chargement...');
                        });
                    },
                )
            })
        } else {
            $('[data-id="social-wall"]').removeClass('is-loading');
        }
    }
}

new BlockCustomerStrateMurSocial()
